<template>
  <div class="container">
    <div class="section">
      <content-area
          v-bind="board"
          :noSharedArea="true"/>
    </div>
  </div>
</template>

<script>
import ContentArea from "@/components/common/ContentArea.vue";
import axiosInstance from "@/plugins/proxyHandler";

export default {
  name: "Shared",
  components: {ContentArea},
  async created() {
    try {
      let {data: {value: board}} = await axiosInstance.post('/api/v1/board/token', {
        token: this.$route.query['token'],
        loginTs: this.$route.query['loginTs']
      })
      this.board = board;
    } catch (e) {
      alert("유효기간이 만료되었거나 잘못된 주소입니다.")
      await this.$router.push('/')
    }
  },
  data() {
    return {
      board: {},
    }
  },
}
</script>

<style scoped>

</style>
